@import "@/assets/styles/global/lib.scss";

.page-request-state{
  .loading-msg {
    text-align: center;
    img {
      margin-right: 10px;
    }
  }
  .loader {
    display: inline-block;
    width: size(30);
    height: size(30);
    animation: spin 2s linear infinite;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
