@import "@/assets/styles/global/lib.scss";

.image-item{
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
  z-index: 1;
  &.align-left {
    text-align: left;
  }

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
  }

  .loader, .placeholder-data, .placeholder-data-img {
    height: 100%;
  }

  picture, img {
    text-align: center;
    height: 100%;
    max-width: 100%;
    object-fit: contain;
    &.max-width-90 {
      width: size(90);
    }
  }

  .image-container {
    height: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

  }

  .animate-container {
    transition: opacity 450ms ease-out;
  }

  .show-container {
    opacity: 1;
  }

  .hide-container {
    opacity: 0;
  }
}
