// :root might not be a good idea.
// Ref https://css-tricks.com/breaking-css-custom-properties-out-of-root-might-be-a-good-idea/
html {
  --master-bg: #14234B;
  --master-bg-lite: #F1F5FF;
  --master-text: #fff;
  --master-highlight: #FFDB14;
  --hero-text: #0053D6;
  --regular-text: #333333;
  --secondary-text: #123693;
  --regular-text-inv: #fff;
  --divider: rgba(255, 255, 255, 0.5);
  --gray-line: #D8D8D8;
  --hero-text-variant: #DACDFE;
  --hero-text-variant-2: rgba(0, 83, 214, 0.2);
  --highlight-color: #0053D626;
  --junior-menu-inactive: #F1F5FF;
  --loading-placeholder-bg-lite: rgba(222, 225, 234, 0.5);
  --onboard-bg-color: #F5F5FA;
  --onboard-link-color: #4F37C8;
  --onboard-error-color: #D83E3E;
  // --junior-menu-inactive: #b0c5fd30;

  --box-color-1: #F0EBFF;
  --box-color-1-variant: #E6DDFF;
  --box-color-2: #F0F5F5;
  --box-color-3: #14234B;
  --status-color-good: #D4FFED;
  --status-color-critical: #D66478;
  --status-color-critical-variant: rgba(214, 100, 120, 0.2);
  // --secondary-text-low: rgba(18, 54, 147, 0.2);
  --secondary-text-low: #c3cee1;
  --schema-text-color: #5C6E74;
  --schema-code-background-1: #EBF6FF;
  --schema-code-background-2: #FFE5EE;
  
  // CricketAPI Page Theme Colors
  --ca-title-color: #121212;
  --ca-btn-bg: rgb(76,83,182);
  --ca-border-color: #E2E2E2;
  --ca-features-section-max-width: #{size(982)};
  --ca-btn-seconday-color: #4F37C8;

  --ca-toggle-get-started-section: block;
  --ca-toggle-third-navigation-section: block;

  --regular-font: 'Proxima Nova', proxima-nova, sans-serif;
  --hero-font: 'Playfair Display', playfair-display, serif;
  --code-font: 'Fira Code', monospace;
  --rz-para-font: 'Hero New', hero-new, sans-serif;
  --rz-hero-font: 'Hero New', hero-new, sans-serif;

  // @supports (font-variation-settings: normal) {
  //   --code-font: 'Fira Code VF', monospace;
  // }

  // rzWebsite Colors
  --rz-bg-color: #030303;
  --rz-copy-color: #111111;
  --rz-btn-color: #006AFF;

  --slidein-z-index: 1011;
  --dailog-z-index: 1010;
  --master-menu-z-index: 1000;
  --junior-menu-z-index: 100;
  --senior-menu-z-index: 100;
  --goto-top-z-index: 2;
  --nav-box-z-index: -1;

  --para-max-width: #{size(850)};
  --para-2-max-width: #{size(1000)};

  // Both value must be same.
  // --master-menu-height changes to 0 when user scrolls down
  // but --master-menu-container-height remains the same
  --master-menu-height: #{size(36)};
  --master-menu-container-height: #{size(36)};

  
  --master-menu-transition-duration: 0.4s;
  --master-menu-transition-delay: 0;

  --junior-menu-height: #{size(40)};
  --junior-menu-width: #{size(210)};
  --junior-menu-pad-top: #{size(16)};
  --junior-menu-pad-right: #{size(16)};
  --senior-menu-width: inherit;
  --hero-title-height: #{size(36)};

  --docs-page-pad-top: #{size(60)};
  --docs-page-pad-right: #{size(16)};
  --docs-page-pad-bottom: #{size(20)};
  --docs-page-pad-left: #{size(16)};
  --docs-page-pad-secondary-right: #{size(16)};

  --para-title-font-size: #{size(16)};
  --para-hero-font-size: #{size(16)};
  --para-hero-line-height: #{size(24)};
  --para-font-size: #{size(14)};
  --para-line-height: #{size(20)};
  --small-text-font-size: #{size(10)};

  --title-font-size: #{size(16)};
  --title-line-height: #{size(16)};
  --title-letter-space: 0;
  --title-pad-bottom: #{size(20)};

  --title-2-font-size: #{size(18)};
  --title-2-line-height: #{size(24)};
  --title-4-font-size: #{size(14)};

  --schema-type-font-size: #{size(7)};
  --schema-variable-font-size: #{size(10)};
  --schema-variable-line-height: #{size(12)};
  --btn-pad-top: #{size(14)};
  --btn-pad-left: #{size(20)};
  --btn-tiny-pad-top: #{size(7)};
  --btn-tiny-pad-left: #{size(14)};
  
  --content-max-width: #{size(1440)};
  --home-page-header-max-width: #{size(1178)};
  --home-page-content-max-width: #{size(990)};

  --hero-img-height: #{size(300)};

  --section-title-font-size: #{size(28)};
  --section-title-line-height: #{size(37)};

  --section-para-font-size: #{size(16)};
  --section-para-line-height: #{size(27)};
  --section-para-pad-right: #{size(16)};

  --section-pad-top: #{size(27)};
  --section-pad-left: #{size(16)};

  --section-box-pad-top: #{size(20)};
  --section-box-pad-left: #{size(12)};

  --section-box-content-pad-top: #{size(20)};
  --section-box-content-pad-bottom: #{size(20)};
  --section-box-content-pad-left: #{size(20)};
  
  --section-container-margin: #{size(75)};

  --banner-btn-font-size: #{size(14)};
  --banner-btn-line-height: #{size(17)};

  // rz-website-theme
  --rz-hero-font-size: #{size(48)};
  --rz-hero-line-height: #{size(56)};
  --rz-brand-type-reg-font-size: #{size(16)};
  --rz-brand-type-reg-line-height: #{size(24)};
  --rz-brand-type-large-font: ;

  --rz-title-level0-font-size: #{size(32)};
  --rz-title-level0-line-height: #{size(48)};
  --rz-title-level2-font-size:  #{size(24)};
  --rz-title-level2-line-height:  #{size(32)};
  --rz-title-section-font-size: #{size(18)};
  --rz-title-section-line-height: #{size(36)};
  --rz-title-small-font: ;

  --rz-para-hero-font-size: #{size(16)};
  --rz-para-hero-line-height: #{size(24)};
  --rz-para-reg-font-size: #{size(18)};
  --rz-para-reg-line-height: #{size(22)};
  --rz-title-small-font-size: #{size(12)};
  --rz-title-small-line-height: #{size(16)};
  --rz-link-reg-font-size: #{size(15)};
  --rz-link-reg-line-height: #{size(16.5)};

  --rz-highlight-color: #FAF5F5;
  --rz-brand-type-color: #262E3B;
  --rz-hero-color: #6B487C;
  --rz-title-color: #000000;
  --rz-type-color: #262E3B;
  --rz-link-color: #0055BF;
  --rz-cta-color: #7B61FF;
  --rz-cta-light-color: #ECE8FF;
  --rz-dark-color: ;

  //rs-cricket-font-codes
  --rs-title-hero-font-size: #{size(40)};
  --rs-title-hero-line-height: #{size(50)};

  --rs-title-level0-font-size: #{size(30)};
  --rs-title-level0-line-height: #{size(44)};

  --rs-title-level1-font-size: #{size(26)};
  --rs-title-level1-line-height: #{size(36)};

  --rs-title-level2-font-size: #{size(24)};
  --rs-title-level2-line-height: #{size(34)};

  --rs-title-level3-font-size: #{size(24)};
  --rs-title-level3-line-height: #{size(34)};

  --rs-title-level4-font-size: #{size(18)};
  --rs-title-level4-line-height: #{size(28)};

  --rs-title-level5-font-size: #{size(15)};
  --rs-title-level5-line-height: #{size(21)};

  --rs-md-label-font-size: #{size(13)};
  --rs-md-label-line-height: #{size(18)};

  --rs-sm-label-font-size: #{size(12)};
  --rs-sm-label-line-height: #{size(17)};

  --rs-para-font-size: #{size(18)};
  --rs-para-line-height: #{size(25)};

  --rs-para-level0-font-size: #{size(16)};
  --rs-para-level0-line-height: #{size(24)};

  --rs-solution-para-font-size: #{size(14)};
    --rs-solution-para-line-height: #{size(20)};

  //rs-cricket-color-codes
  --rs-cta-blue-color: #0055BF;
  --rs-white-color: #ffffff;
  --rs-black-color: #111111;
  --rs-light-black-color: #121212;
  --rs-cta-green-color: #F5FF78;
  --rs-gray-color: #777777;
  --rs-lavender-color: #5141BD;
  --rs-pink-color: #DD4C80;
  --rs-green-color: #00A656;
  --rs-light-blue-color: #58CFDB;
  --rs-light-lavender-color: #A695FF;
  --rs-orange-color: #FF9900;
  --rs-light-pink-color: #FF8282;
  --rs-light-green-color: #97E6AB;
  --rs-blue-color: #4F97F1;
  --rs-dark-blue-color: #1F27A3;
  --rs-red-color: #C83739;
  --rs-banner-blue-color: #2C48A3;
  --rs-border-gray-color: #c4c4c4;


  @media screen and (min-width: $breakpoint-md) {
    --rs-title-hero-font-size: #{size(80)};
    --rs-title-hero-line-height: #{size(90)};

    --rs-title-level0-font-size: #{size(60)};
    --rs-title-level0-line-height: #{size(74)};

    --rs-title-level1-font-size: #{size(40)};
    --rs-title-level1-line-height: #{size(56)};

    --rs-title-level2-font-size: #{size(30)};
    --rs-title-level2-line-height: #{size(42)};

    --rs-title-level3-font-size: #{size(24)};
    --rs-title-level3-line-height: #{size(34)};

    --rs-para-font-size: #{size(22)};
    --rs-para-line-height: #{size(32)};

    --rs-solution-para-font-size: #{size(18)};
    --rs-solution-para-line-height: #{size(28)};

    --rz-title-section-font-size: #{size(24)};
    --junior-menu-pad-right: #{size(24)};
    --master-menu-height: #{size(64)};
    --master-menu-container-height: #{size(64)};
    --senior-menu-width: #{size(160)};
    
    --docs-page-pad-top: #{size(56)};
    --docs-page-pad-right: #{size(24)};
    --docs-page-pad-bottom: #{size(32)};
    --docs-page-pad-left: #{size(28)};
    --docs-page-pad-secondary-right: #{size(24)};

    --para-title-font-size: #{size(20)};
    --para-hero-font-size: #{size(24)};
    --para-hero-line-height: #{size(38)};
    --para-font-size: #{size(16)};
    --para-line-height: #{size(24)};
    --small-text-font-size: #{size(12)};

    --title-font-size: #{size(52)};
    --title-line-height: #{size(69)};
    --title-letter-space: 0.02em;
    --title-pad-bottom: #{size(22)};


    --title-2-font-size: #{size(28)};
    --title-2-line-height: #{size(37)};
    --title-4-font-size: #{size(18)};

    --schema-type-font-size: #{size(8)};
    --schema-variable-font-size: #{size(12)};
    --schema-variable-line-height: #{size(15)};

    --hero-img-height: #{size(450)};

    --section-title-font-size: #{size(40)};
    --section-title-line-height: #{size(53)};

    --section-para-font-size: #{size(22)};
    --section-para-line-height: #{size(29)};

    --section-pad-top: #{size(60)};
    --section-pad-left: #{size(28)};
    
    --section-box-pad-top: #{size(28)};
    --section-box-pad-left: #{size(20)};

    --section-box-content-pad-top: #{size(40)};
    --section-box-content-pad-bottom: #{size(40)};
    --section-box-content-pad-left: #{size(64)};

    --section-container-margin: #{size(100)};

    --banner-btn-font-size: #{size(22)};
    --banner-btn-line-height: #{size(27)};

  }
  
  @media screen and (min-width: $breakpoint-lg) {
    --rz-hero-font-size: #{size(70)};
    --rz-hero-line-height: #{size(78)};
    --rz-title-level2-font-size:  #{size(60)};
    --rz-title-level2-line-height:  #{size(70)};
    --rz-title-level0-font-size: #{size(32)};
    --rz-title-level0-line-height: #{size(48)};
    --rz-para-hero-font-size: #{size(22)};
    --rz-para-hero-line-height: #{size(30)};
    
    --junior-menu-height: #{size(56)};
    // --junior-menu-pad-top: #{size(25)};
    --junior-menu-pad-right: #{size(55)};

    --senior-menu-width: #{size(204)};

    --docs-page-pad-top: #{size(54)};
    --docs-page-pad-right: #{size(55)};
    --docs-page-pad-bottom: #{size(52)};
    --docs-page-pad-left: #{size(46)};
    --docs-page-pad-secondary-right: #{size(150)};

    --para-title-font-size: #{size(27)};
    --para-hero-font-size: #{size(30)};
    --para-hero-line-height: #{size(50)};
    --para-font-size: #{size(18)};
    --para-line-height: #{size(26)};
    --small-text-font-size: #{size(12)};

    --title-font-size: #{size(76)};
    --title-line-height: #{size(92)};
    --title-letter-space: 0.02em;
    --title-pad-bottom: #{size(32)};

    --title-2-font-size: #{size(38)};
    --title-2-line-height: #{size(51)};
    --title-4-font-size: #{size(21)};

    --schema-type-font-size: #{size(14)};
    --schema-variable-font-size: #{size(14)};
    --schema-variable-line-height: #{size(17)};

    --hero-img-height: #{size(580)};

    --section-title-font-size: #{size(76)};
    --section-title-line-height: #{size(92)};

    --section-para-font-size: #{size(27)};
    --section-para-line-height: #{size(36)};

    --section-pad-top: #{size(100)};
    --section-pad-left: #{size(46)};

    --section-box-pad-top: #{size(32)};
    --section-box-pad-left: #{size(20)};
    --section-para-pad-right: #{size(0)};

    --section-box-content-pad-top: #{size(64)};
    --section-box-content-pad-bottom: #{size(64)};
    --section-box-content-pad-left: #{size(144)};

    --section-container-margin: #{size(150)};

    --banner-btn-font-size: #{size(24)};

  }
}

body {
  &.theme-dark {
    background: #000;

    --master-bg: #14234B;
    --master-text: #fff;
    --master-highlight: #FFDB14;
    --hero-text: #0053D6;
    --regular-text: #fff;
    --secondary-text: #123693;
    --regular-text-inv: #fff;
    --divider: rgba(255, 255, 255, 0.5);
    --gray-line: #D8D8D8;
    --hero-text-variant: #DACDFE;
  
    --box-color-1: #000;
    --docs-text : #333;
  }
}
